@font-face {
	font-family: 'Computer Modern Serif';
	src: url('../assets/fonts/cmu/cmunrm.eot');
	src: url('../assets/fonts/cmu/cmunrm.eot?#iefix') format('embedded-opentype'),
		 url('../assets/fonts/cmu/cmunrm.woff') format('woff'),
		 url('../assets/fonts/cmu/cmunrm.ttf') format('truetype'),
		 url('../assets/fonts/cmu/cmunrm.svg#cmunrm') format('svg');
	font-weight: normal;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Computer Modern Serif';
	src: url('../assets/fonts/cmu/cmunti.eot');
	src: url('../assets/fonts/cmu/cmunti.eot?#iefix') format('embedded-opentype'),
		 url('../assets/fonts/cmu/cmunti.woff') format('woff'),
		 url('../assets/fonts/cmu/cmunti.ttf') format('truetype'),
		 url('../assets/fonts/cmu/cmunti.svg#cmunti') format('svg');
	font-weight: normal;
	font-style: italic;
  font-display: swap;
}

@font-face {
	font-family: 'Computer Modern Serif Bold';
	src: url('../assets/fonts/cmu/cmunbi.eot');
	src: url('../assets/fonts/cmu/cmunbi.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/cmu/cmunbi.woff') format('woff'),
		url('../assets/fonts/cmu/cmunbi.ttf') format('truetype'),
		url('../assets/fonts/cmu/cmunbi.svg#cmunbi') format('svg');
	font-weight: bold;
	font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'tos';
  src: local('tos'), url(../assets/fonts/tos/tos-bold.woff) format('woff');
  font-display: swap;
}

