$yellow: #ffce38;
@import "../../styles/vars";

.settings {
    min-height: calc(100vh - 242px);
    width: 960px;
    height: calc(100% - 350px);
    background: white;

    .badge {
        align-self: center;
        width: fit-content;

        color: white;
        font-size: 15px;
        font-weight: 700;
        background: $yellow;
        border: 2px solid $yellow;
        border-radius: 5px;
        padding: 2px;
        span {
            display: flex;

        }
    }
    .badge .free {
        background: grey;
        border: 2px solid grey;
    }

    .settings-form {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 2.0em;
        align-items: inherit;

        > input {
            border: 1px solid #eee;
            width: 90%;

            outline: none;
            height: 50px;
            border-radius: 4px;
            transition: all .15s ease;
            font-size: 18px;
            color: #111;
            padding: 0 24px;
            &:focus {
                border-color: #2188ff;
                box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075), 0 0 0 0.2em rgba(3, 102, 214, 0.3);
            }
        }

        .update-card-button {
            margin-left: 1rem;
            color: $primary-color;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }

        > p {
            text-align: left;
        }
        div, span {
            text-align: left;
        }
        > label {
            font-weight: bold;
            text-align: right;
        }
        > button {
            width: min-content;
            text-align: right;
        }
    }
    button {
        margin-top: 3rem;

    }
}
