.modalContainer {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  transition: opacity .3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #484848;
  padding: 1.5rem;
}

.modalSection {
  width: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 10px 1px rgba(41, 49, 89, 0.15);
  transition: all .3s ease;
  padding: 1.5rem;
}

.modalTitle {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0 2rem;
}

.modalText {
  font-size: 1rem;
  margin: 0 0 1.5rem;
}

.modalButton {
  margin: 0.5rem 0.5rem 0 0;
}
