@import "./styles/vars";

.mainContainer {
  width: 960px;
}

.error {
  font-weight: 300;
  color: #bb0000;
  margin: 0.5rem 0;
}

a {
    text-decoration: none;
}

.mainSection {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mainSectionColumn {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  padding: 3rem 1.5rem;
  background-color: #fff;
  margin: 0 0 1rem;
}

.mainSectionColumnTransparent {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  padding: 3rem 0 0;
  margin: 0 0 1rem;
}

.heroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  background-color: $primary-color;
  width: 100%;
  color: #fff;
  text-align: center;
}

.heroCTASection {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0;
}

.emailInput {
  height: 3rem;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  color: #fff;
  text-align: center;
  transition: all .15s ease-in-out;
  border-radius: 4px 4px 0 0;
  transition: all .15s ease;
  font-size: 1.2rem;
  color: #111;
}

.heroSubscribeButton {
  padding: 1rem 10vw;
  margin: 0;
  border: none;
  font-weight: 600;
  width: 590px;
  font-size: 1.2rem;
  outline: none;
  transition: all .15s ease-in-out;
  border-radius: 0 0 4px 4px;
  background: #ffce38;
  color: #6e5817;
  cursor: pointer;
  box-shadow: 0 3px 0 0 #6e5817;
}

.subscribeCTAButton {
  background: #ffce38;
  color: #6e5817;
  box-shadow: 0 3px 0 0 #6e5817;
}

.coachingCTAButton {
  background: #919191;
  color: #25333d;
  box-shadow: 0 3px 0 0 #25333d;
}

.coachingCTAButton:hover {
  transform: none;
}

.subscribeCTANav {
  margin: 0 0 4rem;
}

.ctaText {
  font-size: 0.8rem;
  font-weight: 300;
  margin: 0.5rem 0;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: left;
}

.step {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
}

.stepSection {
  display: flex;
  flex-direction: row;
  margin: 0 0 1.5rem;
}

.stepHeader {
  display: flex;
  line-height: 120%;
  align-items: center;
  font-weight: 500;
  font-size: 1.5rem;
  margin: 0;
}

.stepNumber {
  color: #25333d;
  line-height: 100%;
  margin: 0 1rem 0 0;
  font-weight: 500;
  font-size: 1rem;
  border: 2px solid #25333d;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 1.5rem;
  height: 1.5rem;
}

.stepText {
  font-size: 1.1rem;
  font-weight: 300;
  padding: 0 0 0.25rem 2.7rem;
}

.premium {
  display: flex;
  flex-direction: column;
  height: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  background: #ffce38;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}

.subtitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0 2rem;
}

.subtitleText {
  margin: 0.2rem 0 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: #888;
  text-transform: uppercase;
}

.facebookWordmark {
  height: 1.2rem;
  margin: 0 0 0.2rem 0.5rem;
}

.solutionSection {
  margin: 3rem 0 0;
}

.successStoriesHeader {
  margin: 0 0 5rem;
}

.successStoriesSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 4.5rem;
  margin: 0;
}

.testimonial {
  background: #fff;
  text-align: center;
  border-radius: 0.5rem;
  padding: 4rem 1.5rem 1.5rem;
  position: relative;
  width: 16rem;
}

.avatar {
  top: -40px;
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  left: calc(50% - 40px);
}

.testimonialHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.5rem;
}

.testimonialCustomer {
  font-weight: 700;
}

.testimonialPlus {
  margin: 0 1rem;
  color: #888;
}

.google {
  height: 1.1rem;
}

.amazon {
  height: 1.4rem;
}

.microsoft {
  height: 1.3rem;
}

.facebook {
  height: 1rem;
  margin: 0 0 0.2rem;
}

.uber {
  height: 1.1rem;
  margin: 0 0 0.1rem;
}

.shopify {
  height: 1.5rem;
}

.yelp {
  height: 2rem;
  margin: 0 0 -0.1rem;
}

.testimonialText {
  font-size: 0.95rem;
  font-weight: 300;
  line-height: 1.65rem;
}

.pricingSection {
  display: flex;
  width: 100%;
  padding: 1.5rem 0;
}

.pricingSectionInner {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  border-radius: 4px;
}

.monthlyPricingSection {
  border: 2px solid $primary-color;
}

.yearlyPricingSection {
  border: 2px solid #ffce38;
}

.coachingPricingSection {
  border: 2px solid #919191;
}

.pricingHeader {
  border-radius: 4px 4px 0 0;
}

.monthlyPricingHeader {
  background-color: $primary-color;
}

.yearlyPricingHeader {
  background-color: #ffce38;
}

.coachingPricingHeader {
  background-color: #919191;
}

.pricingHeaderText {
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.monthlyPricingHeaderText {
  color: #fff;
}

.yearlyPricingHeaderText {
  color: #6e5817;
}

.coachingPricingHeaderText {
  color: #25333d;
}

.pricingContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.billPeriod {
  font-size: 0.8rem;
  font-weight: 700;
}

.currencyType {
  color: #888;
  font-size: 0.7rem;
  font-weight: 700;
}

.pricingType {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.price {
  font-size: 3rem;
  font-weight: 700;
  margin: 1.5rem 0;
}

.monthlyPricingTypeText, .monthlyPrice {
  color: $primary-color;
}

.yearlyPricingTypeText, .yearlyPrice {
  color: #6e5817;
}

.coachingPricingTypeText, .coachingPrice {
  color: #25333d;
}

.benefitsSection {
  display: flex;
  flex-direction: column;
  margin: 0.5rem auto 3rem;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5rem;
  list-style-type: none;
  padding: 0;
  flex-grow: 1;
  text-align: left;
}

.mark {
  margin: 0 0.5rem 0 0;
  font-weight: 700;
}

.monthlyPackage {
  color: #25333d;
}

.yearlyPackage {
  color: #6e5817;
}

.coachingPackage {
  color: #25333d;
}

.secureCheckout {
  font-size: 0.7rem;
  color: #888;
  font-weight: 700;
  margin: 0.5rem 0 0;
}

.secureCheckoutIcon {
  height: 0.6rem;
  margin: 0 0.25rem 0 0;
}

.verifyContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  min-height: 70vh;
}

.verifySection {
  margin: 1rem 0;
}

.verifyTitle {
  font-family: -apple-system, system-ui, sans-serif;
  margin: 0;
  font-size: 2rem;
}

.verifyHeader {
  font-family: -apple-system, system-ui, sans-serif;
  margin: 0 0 1rem;
  font-size: 1.5rem;
}

.verifyText {
  font-family: -apple-system, system-ui, sans-serif;
  margin: 0;
  font-size: 1rem;
}

.goBack {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.linkText {
  color: $primary-color;
  text-decoration: none;
}

.faq {
  margin: 2rem 6rem;
  text-align: left;
  align-self: flex-start;
}

.faqHeader {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 0 1rem 0;
}

.faqText {
  font-size: 1.15rem;
  font-weight: 300;
  line-height: 1.9rem;
  color: #666;
  margin: 0;
}

.faqList {
  margin: 1rem 0 0;
}

.qaSection {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 4px;
  background: #fbfbfb;
  border: 2px solid #f1f1f1;
  text-align: left;
}

.questionText {
  font-size: 1.2rem;
  font-weight: 300;
  font-family: serif;
  margin: 0 0 1rem;
}

.explanation {
  font-size: 1.4rem;
  font-weight: 700;
  font-family: serif;
}

.checkmark {
  height: 8rem;
  margin: 0 0 2rem;
}

.separator {
  margin: 2rem 0 1rem;
  border-bottom: 2px solid #f1f1f1;
}

.shareContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 20rem;
  padding: 0;
  border-radius: 4px;
  border: 2px solid $primary-color;
}

.shareLink {
  padding: 0.5rem 0 0.5rem 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: flex-start;
}

.shareCTA {
  display: flex;
  font-weight: 500;
  color: $primary-color;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0.5rem;
  border-left: 2px solid $primary-color;
  cursor: pointer;
}

.copied {
  color: #2ab380;
  border-color: #2ab380;
}

.navButton {
  text-decoration: none;
}

.formSection {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.formInput {
  font-size: inherit;
  display: block;
  flex: 1;
  margin: 0.5rem 0 1rem;
  padding: 1rem;
  width: 30vw;
  font-weight: 300;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
}

.formLabel {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  align-self: flex-start;
}

/* mobile */
@media screen and (max-width: 960px) {
  .mainContainer {
    width: auto;
  }
  .stepSection {
    flex-direction: column;
  }
  .premium {
    margin: 0.5rem auto 0.5rem 2.5rem;
  }
  .successStoriesSection {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .testimonial {
    margin: 2rem 1rem;
    width: auto;
  }
  .pricingSection {
    flex-direction: column;
  }
  .pricingSectionInner {
    margin: 0.5rem 1rem;
  }
  .faq {
    margin: 2rem 1rem;
  }
  .verifyContainer {
    margin: 0 1rem;
  }
  .sharLink, .shareCTA {
    padding: 0.5rem;
  }
  .shareContainer {
    margin: 0;
  }
  .formInput {
    width: 80vw;
  }
  .heroSubscribeButton {
    width: 100%;
  }
}
