@import "./styles/fonts";
@import "./styles/vars";

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 140%;
  background-color: #fbfbfb;
  z-index: 0;
  color: #25333d;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
h1, h2, h3, h4, h5, p, span {
  margin: 0;
  padding: 0;
  line-height: 140%;
}

.serif {
  font-family: "tos", "Apple Garamond", "Baskerville", "Times New Roman", "Droid Serif", "Times", "Source Serif Pro", serif;
}

h1 {
  font-family: serif;
  line-height: 120%;
  font-size: 3rem;
  font-weight: 700;
  margin: 0 0 1.5rem;
}

h2 {
  font-size: 1.5rem;
  margin: 0 0 1.5rem;
  font-weight: 300;
}

h3 {
  font-size: 1.7rem;
  font-family: serif;
  margin: 0 0 1.5rem;
  font-weight: 700;
}

button {
  padding: 1rem 2vw;
  margin: 0;
  border: none;
  font-weight: 600;
  font-size: 1.1rem;
  outline: none;
  transition: all .15s ease-in-out;
  border-radius: 4px;
  cursor: pointer;
  background: $primary-color;
  color: #fff;
  box-shadow: 0 3px 0 0 darken($primary-color, 10%);
  transition: all 150ms ease-in-out;
}

button.red {
    color: white;
    background-color: #ed6962;
    box-shadow: 0 3px 0 0 #b73343;
}

button:hover {
    cursor: pointer;
    transform: translateY(-1px);
}

blockquote {
  font-size: 1.1rem;
  font-weight: 500;
  font-family: sans-serif;
  color: #fff;
  padding: 0 1rem;
  background-color: $primary-color;
  margin: 1rem 0 2rem;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
}

blockquote::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 5%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: $primary-color;
  border-bottom: 0;
  border-right: 0;
  margin-right: -10px;
  margin-bottom: -20px;
}

.appContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
}

@media screen and (max-width: 960px) {
  button {
    padding: 1rem 4vw;
  }
}
